import { z } from 'zod';

import { LocaleSchema } from './LocaleSchema';

export const EnabledLocaleSchema = z.object({
	label: z.string(),
	locale: LocaleSchema,
	dateFnsLocale: z.string(),
	matchingLocales: z.array(z.string()).default([]),
	disabled: z.boolean().optional().default(false)
});

export type EnabledLocaleSchemaType = z.infer<typeof EnabledLocaleSchema>;
