// @ts-check
/* eslint-disable no-restricted-properties */

import { atomStore } from '../../data-access/atoms/atomStore';
import { settingsAtom } from '../../settings/hook/settings.atom';

import { initSentry } from './sentry.common';

const getRates = () => atomStore.get(settingsAtom).sentrySampleRates;

/**
 * @param {Parameters<typeof import('@sentry/nextjs').init>[0] & { version: string; }} options
 * @returns {void}
 * */
export const initSentryClient = options => {
	return initSentry({
		...(!(process.env.NEXT_PUBLIC_SENTRY_TUNNEL_DISABLED === 'true')
			? { tunnel: process.env.NEXT_PUBLIC_SENTRY_TUNNEL }
			: {}),
		// we can't reuse above getRates values since it has to be called each time tracesSampler is called in order to
		// use remote BO configuration values after it's been loaded
		tracesSampler: () => getRates().traces,
		profilesSampleRate: getRates().profiles,
		// since those two does not provide ability to use function, we  can just rely on the above getRates
		replaysOnErrorSampleRate: getRates().replaysOnError,
		replaysSessionSampleRate: getRates().replaysSession,
		beforeSend: event => {
			const { exception, message } = event || {};
			const { type: errorTitle, value: errorMessage } = exception?.values?.[0] || {};
			const { errors } = atomStore.get(settingsAtom).sentrySampleRates;

			// we try to match the current error with one of those specificed in the BO
			const customErrorSampleRate = errors?.find(error => {
				const match = (str = '') => new RegExp(error.match || '').test(str);

				return match(errorTitle) || match(errorMessage) || match(message);
			});

			const rate = customErrorSampleRate?.rate ?? 1;

			// we create our own sampling system here
			return rate > 0 && rate >= Math.random() ? event : null;
		},
		platformType: 'client',
		...options
	});
};
