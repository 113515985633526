/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { z } from 'zod';

import { EnabledLocaleSchema } from '../../localization/locale/EnabledLocaleSchema';
import { TabNavigationItemName } from '../../models/navigation';
import ZodBuilderUtils from '../utils/ZodBuilderUtils';

import { AppStoreReviewConfigSchema, ExchangeSchema, PwaForceInstallSchema } from './schemas';

export const SettingsSchema = z
	.object({
		isBrowserSectionEnabled: z.boolean().default(false).describe('Is browser section enabled'),
		isTradeSectionEnabled: z.boolean().default(false).describe('Is trade section enabled'),
		tabNavigationItems: z
			.array(
				z.object({
					name: z.nativeEnum(TabNavigationItemName),
					order: z.number()
				})
			)
			.min(4)
			.max(5)
			.default([
				{ name: TabNavigationItemName.Home, order: 0 },
				{ name: TabNavigationItemName.Wallet, order: 1 },
				{ name: TabNavigationItemName.Hunt, order: 2 },
				{ name: TabNavigationItemName.Sports, order: 3 },
				{ name: TabNavigationItemName.Rewards, order: 4 }
			])
			.describe(`Tab navigation items, valid names: ${Object.values(TabNavigationItemName).join(', ')}`),
		featureYourAppUrlPoblano: z.string().default('').describe('Feature your app'),
		pushNotificationsPromptMinutesDelay: z.number().default(15).describe('Push notifications prompt minutes delay'),
		pushNotificationsActionsPromptMinutesDelay: z
			.number()
			.default(15)
			.describe('Push notifications prompt minutes delay after user actions'),
		isPushNotificationsEnabled: z.boolean().default(false).describe('Is push notifications enabled'),
		JumioEnabledInterface: ZodBuilderUtils.fromJsonStringSchema(
			z.object({ web: z.boolean(), ios: z.boolean(), android: z.boolean() }),
			{
				web: false,
				ios: false,
				android: false
			}
		).describe('Enable / disable jumio interface on particular platform'),
		POImetresAway: z.number().default(-220).describe('Distance in metres from POI'),
		POIpositionFromUser: z.number().default(250).describe('Distance from the user in metres from POI'),
		accountVerificationLink: z
			.string()
			.default('https://socios.zendesk.com/hc/en-001/sections/360005704877-Account-Verification')
			.describe('Account verification link'),
		allowDecimalTokenTransaction: z.boolean().default(false).describe('Allow decimal token transaction'),
		androidVersion: z.string().default('0.0.0').describe('Android version'),
		appStoreUrlCapsicum: z.string().default('https://itunes.apple.com/app/id1464868277').describe('App store url'),
		playStoreUrlCapsicum: z
			.string()
			.default('https://play.google.com/store/apps/details?id=com.socios')
			.describe('Play store url'),
		auctionRefreshInterval: z.number().default(30).describe('Auction refresh interval'),
		bankOfNegaraLink: z
			.string()
			.default('https://www.bnm.gov.my/fep#notices')
			.describe('Link to documentation on Bank of Negara '),
		cc2ExplorerLink: z.string().default('https://scan.chiliz.com/').describe('CC2 explorer link'),
		chilizAndSociosInsiderTradingPolicyLink: z
			.string()
			.default('https://www.socios.com/insider-trading-policy/')
			.describe('Chiliz and Socios Insider Trading Policy Link'),
		chzAcquisitionCampaignConfig: ZodBuilderUtils.fromJsonStringSchema(
			z.object({
				dateStart: z.string(),
				dateEnd: z.string(),
				kycDelay: z.string(),
				vatCountries: z.array(z.string())
			}),
			{
				dateStart: '',
				dateEnd: '',
				kycDelay: '',
				vatCountries: []
			}
		).describe('Acquisition campaign config'),
		comeJoinUsLink: z.string().default('https://www.chiliz.com/en/careers/').describe('Come join us link'),
		contactUsHereLink: z
			.string()
			.default('https://mobile-app-content.socios.com/en_gb/contact-us/')
			.describe('Contact us link'),
		contactUsHereLinkForUsaCapsicum: z
			.string()
			.default('https://socios-us.zendesk.com/hc/en-us')
			.describe('Contact us link for USA'),
		cookiesPolicyLink: z.string().default('https://www.socios.com/cookies-policy/').describe('Cookies policy link'),
		defaultCapsicumAvatarUrl: z
			.string()
			.default('https://assets.chiliz.com/images/user/default-avatar/{chilizUserId}.jpg')
			.describe('Default avatar url'),
		enablePartnerFiltersV2: z.boolean().default(true).describe('Enable V2 of partner filters'),
		enabledBehaviorsToasts: ZodBuilderUtils.fromJsonStringSchema(z.array(z.string()), []).describe(
			'Enabled behaviors toasts'
		),
		enabledLocales: ZodBuilderUtils.fromJsonStringSchema(z.array(EnabledLocaleSchema), []).describe(
			'Enabled locales'
		),
		enabledUsaFanTokenWithdrawal: z.boolean().default(false).describe('Enable USA fan token withdrawal'),
		exchangesNetworkCompatibility: z
			.object({
				exchanges: z.array(ExchangeSchema)
			})
			.default({ exchanges: [] })
			.describe('Exchanges network compatibilitie'),
		facebookLink: z.string().default('https://www.facebook.com/sociosdotcom/').describe('Facebook link'),
		fanTokenComLink: z.string().default('https://www.fantokens.com/').describe('Fan token webside link'),
		faqsLink: z.string().default('https://socios.zendesk.com/hc/fr').describe('FAQs link'),
		feedCachePersistenceDuration: z.number().default(120000).describe('Feed cache persistence duration'),
		'feedCachePersistenceDuration_2.30.2': z
			.number()
			.default(180000)
			.describe('Feed cache persistence duration (for v2.30.2)'),
		feedPullToRefreshAvailAttempts: z.number().default(3).describe('Feed pull to refresh available attempts'),
		ftoPaymentTimeoutSeconds: z.number().default(900).describe('FTO payment timeout in seconds'),
		howDoIAdd$CHZLink: z
			.string()
			.default('https://socios.zendesk.com/hc/en-001/articles/4403721183121-Adding-CHZ-to-your-wallet-')
			.describe('How do I add $CHZ link'),
		howDoIGetFanTokensLink: z
			.string()
			.default('https://socios.zendesk.com/hc/en-001/articles/4403726960145-How-do-I-get-Fan-Tokens-')
			.describe('How do I get fan tokens link'),

		imageOptimizationQuality: z.number().default(85).describe('Quality of the image optimization'),
		imageOptimizationProvider: z
			.string()
			.default('next')
			.describe('Provider for image optimization (next | cloudflare)'),
		inHouseSSUReward: z
			.boolean()
			.default(true)
			.describe('whether to use in house SSU Reward or not. False will use GWEN service'),
		instagramLink: z.string().default('https://www.instagram.com/sociosdotcom/').describe('Instagram link'),
		iosChilizXAppLink: z
			.string()
			.default('https://apps.apple.com/fr/app/chiliz-exchange/id1493980225')
			.describe('iOS Chiliz X App link'),
		iosVersion: z.string().default('1.0.0').describe('iOS version'),
		isATTPopupEnabled: z.boolean().default(false).describe('Is ATT popup enabled'),
		isAccountManagementEnabled: z.boolean().default(true).describe('Is account management enabled'),
		isAchievementsV2Enabled: z.boolean().default(false).describe('Is achievements V2 enabled'),
		isAchievementsV2EnabledV239Up: z.boolean().default(false).describe('Is achievements V2 enabled (v2.39.0+)'),
		isBrazeEnabled: z.boolean().default(false).describe('Enable the Braze customer engagement integration'),
		isCapsicumTopUpEnabled: z
			.boolean()
			.default(false)
			.describe('flag indicates if Top Up feature is enabled in Capsicum'),
		isCC2Enabled: z.boolean().default(true).describe('Is CC2 enabled'),
		isCheckInUploadCameraEnabled: z.boolean().default(false).describe('Is check in upload camera enabled'),
		isCheckInUploadGalleryEnabled: z.boolean().default(false).describe('Is check in upload gallery enabled'),
		isCheckInUploadImagesEnabled: z.boolean().default(false).describe('Is check in upload images enabled'),
		isCheckinEnabled: z.boolean().default(false).describe('Is check in enabled'),
		isCodePushUpdateEnabled: z.boolean().default(false).describe('Is CodePush update enabled'),
		isThemeSelectionEnabled: z
			.boolean()
			.default(false)
			.describe('whether switching between light & dark mode is enabled in the app'),
		isDigitalCollectiblesEnabled: z.boolean().default(true).describe('Is digital collectibles enabled'),
		isDigitalCollectiblesWithdrawalEnabled: z
			.boolean()
			.default(true)
			.describe('Is digital collectibles withdrawal enabled'),
		isFanRewardEnabled: z.boolean().default(false).describe('Is fan reward enabled'),
		isFanTokenDirectPurchaseEnabled: z.boolean().default(false).describe('Is fan token direct purchase enabled'),
		fanTokenDirectPurchaseProcessExpirationPeriod: z
			.string()
			.default('PT15M')
			.describe('Period in mins that user have to finish the FanToken Direct Purchase journey'),
		topUpProcessExpirationPeriod: z
			.string()
			.default('PT15M')
			.describe('Period in mins that user have to finish the top up journey'),
		isFanTokenStakingEnabled: z.boolean().default(false).describe('Is fan token staking enabled'),
		isFavouriteTeamEnabled: z.boolean().default(true).describe('Is favourite team enabled'),
		isFreekickEnabled: z.boolean().default(false).describe('Is freekick enabled'),
		isFreemiumCheckInEnabled: z.boolean().default(false).describe('Is freemium check in enabled'),
		isFreemiumFreekickChallengeEnabled: z
			.boolean()
			.default(false)
			.describe('Is freemium freekick challenge enabled'),
		isFreemiumLeaderboardsEnabled: z.boolean().default(false).describe('Is freemium leaderboards enabled'),
		isFreemiumPredictionAndCheckInInformativeModalEnabled: z
			.boolean()
			.default(false)
			.describe('Is freemium prediction and check in informative modal enabled'),
		isFreemiumPredictorEnabled: z.boolean().default(false).describe('Is freemium predictor enabled'),
		isFreemiumSwapAndPopEnabled: z.boolean().default(false).describe('Is freemium swap and pop enabled'),
		isFreemiumWeeklyTriviaEnabled: z.boolean().default(false).describe('Is freemium weekly trivia enabled'),
		isGeestestDisabled: z.boolean().default(false).describe('Is Geestest disabled'),
		isGeetestDisabled: z.boolean().default(false).describe('Is Geetest disabled'),
		isGeetestDisabledCapsicum: z.boolean().default(false).describe('Is Geetest disabled (Capsicum only)'),
		isImageOptimizationEnabled: z.boolean().default(false).describe('Is image optimization enabled'),
		isInUseEnabled: z.boolean().default(false).describe('Is "In use" enabled'),
		isInternationalWalletV2Enabled: z.boolean().default(false).describe('Is international wallet V2 enabled'),
		isInternationalWebWalletV2Enabled: z
			.boolean()
			.default(false)
			.describe('Is international web wallet V2 enabled'),
		isJumioDisabledInWeb: z.boolean().default(false).describe('Is Jumio disabled in web'),
		isJumioEnabled: z.boolean().default(false).describe('Is Jumio enabled'),
		isLeaderboardsEnabled: z.boolean().default(false).describe('Is leaderboards enabled'),
		isLockerRoomEnabled: z.boolean().default(false).describe('Is locker room enabled'),
		isLockerRoomParticipationEnabled: z.boolean().default(false).describe('Is locker room participation enabled'),
		isLockerRoomUnlockingEnabled: z.boolean().default(false).describe('Is locker room unlocking enabled'),
		isLockerRoomTokensNeededLabelEnabled: z
			.boolean()
			.default(true)
			.describe('Is locker room tokens needed label feature enabled on locker room feed posts'),
		isMaintenanceEnabled: z.boolean().default(false).describe('Is maintenance enabled'),
		isMarketingEmailConsentVisible: z
			.boolean()
			.default(false)
			.describe('Is marketing email consent section visible'),
		isMarketplaceEnabled: z.boolean().default(false).describe('Is marketplace enabled'),
		isMarketplaceFanTokenSellEnabled: z.boolean().default(false).describe('Is marketplace fan token sell enabled'),
		isNewFeedTeamSelectionEnabled: z.boolean().default(false).describe('Is new feed team selection enabled'),
		isSuggestedTasksFeedEnabled: z.boolean().default(false).describe('Is suggested tasks feed enabled'),
		isSuggestedTasksFeedFiltersEnabled: z
			.boolean()
			.default(false)
			.describe('Are suggested tasks feed filters enabled'),
		isFavouriteTeamFeedFiltersEnabled: z
			.boolean()
			.default(false)
			.describe('Are favourite team feeed filters enabled'),
		isGreetingsScreenEnabled: z
			.boolean()
			.default(false)
			.describe('Indicates whether the greetings screen should be shown upon user registration'),
		isNewOnboardingEnabled: z.boolean().default(false).describe('Is new onboarding enabled'),
		isNewRegistrationFlowEnabled: z.boolean().default(false).describe('Is new registration flow enabled'),
		isMarketingBannerEnabled: z.boolean().default(false).describe('Is marketing banner enabled on todo'),
		isNCWOnboardingEnabled: z.boolean().default(false).describe('Is ncw onboarding enabled'),
		isNCWTopUpEnabled: z.boolean().default(false).describe('Is ncw top up enabled'),
		isNftWithdrawEnabled: z.boolean().default(false).describe('Is NFT withdraw enabled'),
		isPasswordSettingEnabled: z.boolean().default(true).describe('Is password setting enabled'),
		isPOTMEnabled: z.boolean().default(false).describe('Is POTM enabled'),
		isPollEnabled: z.boolean().default(false).describe('Is poll enabled'),
		isPredictorEnabled: z.boolean().default(false).describe('Is predictor enabled'),
		IsWhatsAppCodesEnabled: z
			.boolean()
			.default(false)
			.describe('Indicates whether users can send login code via WhatsApp'),
		isWeb3Enabled: z.boolean().default(false).describe('Is Web3, non-custodial apps side enabled'),
		isRegistrationByEmailEnabled: z.boolean().default(false).describe('Is registration by email enabled'),
		isReverseAuctionEnabled: ZodBuilderUtils.fromJsonStringSchema(
			z.object({ web: z.boolean(), mobile: z.boolean() }),
			{
				web: false,
				mobile: false
			}
		).describe('Is reverse auction enabled'),
		isSocialFeedEnabled: z.boolean().default(false).describe('Is social feed enabled'),
		isSociosCollectiblesEnabled: z.boolean().default(false).describe('Is Socios collectibles enabled'),
		isSportEventsEnabled: z.boolean().default(false).describe('Is sport events enabled'),
		isSwapAndPopEnabled: z.boolean().default(false).describe('Is swap and pop enabled'),
		isTokenHuntEnabled: z.boolean().default(false).describe('Is Token Hunt enabled'),
		isTokenHuntTemporaryViewOnWebEnabled: z.boolean().default(false).describe('Is T.Hunt Temp View on Web enabled'),
		isTokenHuntSpecialEventsEnabled: z.boolean().default(false).describe('Is Token Hunt Special Events enabled'),
		isTopupEnabled: z.boolean().default(false).describe('Is topup enabled'),
		isTransactionHistoryEnabled: z.boolean().default(true).describe('Is transaction history enabled'),
		isTurkeyMobileBlockEnabled: z.boolean().default(false).describe('Is Turkey mobile block enabled'),
		isTypeformIntegrationEnabled: z.boolean().default(false).describe('Is typeform integration enabled'),
		isUsaFtoEnabled: z.boolean().default(false).describe('Is USA FTO enabled'),
		isUsaTopupEnabled: z.boolean().default(false).describe('Is USA topup enabled'),
		isUsaWalletV2Enabled: z.boolean().default(false).describe('Is USA wallet V2 enabled'),
		isUsaWebWalletV2Enabled: z.boolean().default(false).describe('Is USA web wallet V2 enabled'),
		isUsaWithdrawalEnabled: z.boolean().default(false).describe('Is USA withdrawal enabled'),
		isVoucherEnabled: z.boolean().default(false).describe('Is voucher enabled'),
		isWalletEnabled: z.boolean().default(false).describe('Is wallet enabled'),
		isTradeChartEnabled: z.boolean().default(true).describe('Is trade chart enabled'),
		isPartnerTeamFeedEnabled: z.boolean().default(false).describe('Is partner team feed enabled'),
		isWebAchievementsEnabled: z.boolean().default(false).describe('Is web achievements enabled'),
		isWebCheckInEnabled: z.boolean().default(false).nullable().describe('Is web check in enabled'),
		isWebFanRewardEnabled: z.boolean().default(false).describe('Is web fan reward enabled'),
		isWebLeaderboardsEnabled: z.boolean().default(false).describe('Is web leaderboards enabled'),
		isWebPredictorEnabled: z.boolean().default(false).describe('Is web predictor enabled'),
		isWebRewardsEnabled: z.boolean().default(false).describe('Is web rewards enabled'),
		isWebSportEventsEnabled: z.boolean().default(false).describe('Is web sport events enabled'),
		isWeeklyTriviaEnabled: z.boolean().default(false).describe('Is weekly trivia enabled'),
		isWithdrawalEnabled: z.boolean().default(false).describe('Is withdrawal enabled'),
		isFtoHomeSectionEnabled: z.boolean().default(true).describe('Is fan token offerings home carousel is enabled'),
		isAdvancedTradingEnabled: z.boolean().default(true).describe('Is advanced trading enabled'),
		isInterestsScreenEnabled: z.boolean().default(false).describe('Is interets screen enabled'),
		jumioEnabledInterface: ZodBuilderUtils.fromJsonStringSchema(
			z.object({
				web: z.boolean().default(false),
				ios: z.boolean().default(false),
				android: z.boolean().default(false)
			}),
			{
				web: false,
				ios: false,
				android: false
			}
		).describe('Enable / disable jumio interface on particular platform'),
		jumioUseAdvancedWebview: z.boolean().default(false).describe('Jumio use advanced webview'),
		leaderboardFaqLink: z
			.string()
			.default('https://socios.zendesk.com/hc/en-001/articles/18714741683729-All-About-Leaderboards')
			.describe('Leaderboard FAQ link'),
		linkedinLink: z.string().default('https://www.linkedin.com/company/socios-com/').describe('Linkedin link'),
		mediumLink: z.string().default('https://medium.com/tag/nft').describe('Medium link'),
		mimeTypes: z
			.object({
				IMAGE: z.array(z.string()).optional(),
				VIDEO: z.array(z.string()).optional(),
				AUDIO: z.array(z.string()).optional(),
				APPLICATION: z.array(z.string()).optional()
			})
			.default({
				IMAGE: [],
				VIDEO: [],
				AUDIO: [],
				APPLICATION: []
			})
			.describe('Mime types'),
		minDistanceBetweenPOIs: z.number().default(35).describe('Minimum distance between POIs'),
		minimumAllowedAndroidVersion: z.string().default('v2.63.0').describe('Minimum allowed version (Android only)'),
		minimumAllowedIOSVersion: z.string().default('v2.63.0').describe('Minimum allowed version (iOS only)'),
		minimumAllowedVersionCapsicum: z.string().default('0.0.0').describe('Minimum allowed version (Capsicum only)'),
		mwsBannedCountries: z.array(z.string()).default([]).describe('MWS banned countries'),
		mwsTermsAndConditionsLink: z
			.string()
			.default('https://mobile-app-content.socios.com/en_gb/terms-conditions-mws/')
			.describe('MWS Terms and Conditions link'),
		isRewardPointsMultiplierEnabled: z
			.boolean()
			.default(false)
			.describe('Banned countries for reward points multiplier'),
		partnerPageOrder: z.array(z.string()).default([]).describe('Partner page order'),
		privacyPolicyLink: z
			.string()
			.default('https://mobile-app-content.socios.com/en_gb/privacy-policy/')
			.describe('Privacy policy link'),
		pwaForceInstall: PwaForceInstallSchema.describe('PWA force install'),
		responsibleTradingLink: z
			.string()
			.default('https://www.socios.com/responsible-operation-responsible-trading/')
			.describe('Responsible trading link'),
		sentrySampleRates: z
			.object({
				traces: z.number(),
				profiles: z.number(),
				replaysOnError: z.number(),
				replaysSession: z.number(),
				wsConnexionError: z.number(),
				errors: z
					.array(
						z.object({
							match: z.string(),
							rate: z.number().min(0).max(1)
						})
					)
					.optional()
			})
			.default({
				traces: 0.01,
				profiles: 0.25,
				replaysOnError: 0.2,
				replaysSession: 0,
				wsConnexionError: 0,
				errors: []
			})
			.describe('Sentry sample rates'),
		appStoreReviewConfig: AppStoreReviewConfigSchema.describe('App Store review flow configuration'),
		showCrossSellBanner: z.boolean().default(false).describe('Show cross sell banner'),
		showRequirementsPage: z.boolean().default(false).describe('Show new personal verification page'),
		showTokenNeededLink: z.boolean().default(true).describe('Show Buy Fan Tokens link'),
		preventBuyFanTokensModal: z.boolean().default(false).describe('Prevent Buy Fan Tokens Modal to open'),
		isAppStoreReviewEnabled: z.boolean().default(false).describe('Is mobile app stores review dialog enabled'),
		isGoogleConsentDialogEnabled: z.boolean().default(false).describe('Is google consent dialog enabled'),
		specialEventLeaderboard: ZodBuilderUtils.fromJsonStringSchema(
			z.object({ id: z.string(), status: z.string() }),
			{
				id: '',
				status: 'disabled'
			}
		).describe('Special event leaderboard'),
		sportEventsCutOffTimeInUTC: z
			.string()
			.default('August 17, 2022 05:30:00 UTC')
			.describe('Sport events cut off time'),
		supportedSportsMatches: ZodBuilderUtils.fromJsonStringSchema(z.array(z.string()), []).describe(
			'Supported sports matches'
		),
		supportedSportsMatches_V2: ZodBuilderUtils.fromJsonStringSchema(z.array(z.string()), []).describe(
			'Supported sports matches (v2)'
		),
		supportedSportsPartner: ZodBuilderUtils.fromJsonStringSchema(z.array(z.string()), []).describe(
			'Supported sports partner'
		),
		supportedSportsPartner_V2: ZodBuilderUtils.fromJsonStringSchema(z.array(z.string()), []).describe(
			'Supported sports partner (v2)'
		),
		twitterLink: z.string().default('https://twitter.com/socios').describe('Twitter link'),
		areTutorialsEnabled: z
			.object({
				rewardsLobby: z.boolean(),
				rewardsCategory: z.boolean(),
				auctionsLobby: z.boolean(),
				auctionDetails: z.boolean(),
				partnerPage: z.boolean()
			})
			.default({
				rewardsLobby: false,
				rewardsCategory: false,
				auctionsLobby: false,
				auctionDetails: false,
				partnerPage: false
			})
			.describe('Enable/Disable tutorials by feature'),
		ukApprovedByFooter: z
			.object({
				auction: z.object({ visible: z.boolean(), date: z.string() }),
				buy: z.object({ visible: z.boolean(), date: z.string() }),
				home: z.object({ visible: z.boolean(), date: z.string() }),
				partner: z.object({ visible: z.boolean(), date: z.string() }),
				pay: z.object({ visible: z.boolean(), date: z.string() }),
				poll: z.object({ visible: z.boolean(), date: z.string() }),
				predictor: z.object({ visible: z.boolean(), date: z.string() }),
				sell: z.object({ visible: z.boolean(), date: z.string() }),
				rewardsProduct: z.object({ visible: z.boolean(), date: z.string() }),
				topup: z.object({ visible: z.boolean(), date: z.string() }),
				wallet: z.object({ visible: z.boolean(), date: z.string() }),
				fanTokenWallet: z.object({ visible: z.boolean(), date: z.string() })
			})
			.default({
				auction: { visible: true, date: '20/02/2023' },
				buy: { visible: true, date: '20/01/2023' },
				home: { visible: true, date: '20/03/2023' },
				partner: { visible: true, date: '20/05/2023' },
				pay: { visible: true, date: '16/01/2024' },
				poll: { visible: true, date: '16/01/2024' },
				predictor: { visible: true, date: '16/01/2024' },
				sell: { visible: true, date: '20/07/2023' },
				rewardsProduct: { visible: true, date: '20/09/2023' },
				topup: { visible: true, date: '20/09/2023' },
				wallet: { visible: true, date: '20/11/2023' },
				fanTokenWallet: { visible: true, date: '10/09/2099' }
			})
			.describe('UK approved by footer'),
		ukRiskLink: z.string().default('https://www.twitch.tv').describe('UK risk link'),
		ukRiskLinkVisibility: z
			.object({
				partner: z.boolean(),
				wallet: z.boolean()
			})
			.default({
				partner: true,
				wallet: true
			})
			.describe('UK risk link visibility'),
		ukRiskWarning: z
			.object({
				auction: z.boolean(),
				buy: z.boolean(),
				home: z.boolean(),
				partner: z.boolean(),
				pay: z.boolean(),
				poll: z.boolean(),
				predictor: z.boolean(),
				rewards: z.boolean(),
				rewardsProduct: z.boolean(),
				sell: z.boolean(),
				topup: z.boolean(),
				wallet: z.boolean(),
				fanTokenWallet: z.boolean()
			})
			.default({
				auction: true,
				buy: true,
				home: true,
				partner: true,
				pay: true,
				poll: true,
				predictor: true,
				rewards: true,
				rewardsProduct: true,
				sell: true,
				topup: true,
				wallet: true,
				fanTokenWallet: true
			})
			.describe('UK risk warnings'),
		usaAppLaunchVersion: z.string().default('v0.0.1').describe('USA app launch version'),
		usaHiddenMilestones: ZodBuilderUtils.fromJsonStringSchema(z.array(z.string()), []).describe(
			'Hidden milestones for USA'
		),
		userGuidelinesLink: z
			.string()
			.default('https://socios.zendesk.com/hc/fr/categories/6928967659793-Premier-pas')
			.describe('User guidelines link'),
		webBlockedCountries: z.array(z.string()).default([]).describe('Blocked countries for web'),
		weiboLink: z.string().default('https://weibo.com/sociosdotcom?is_all=1').describe('Weibo link'),
		welcomeScreenModalRegulations: ZodBuilderUtils.fromJsonStringSchema(z.array(z.string()), []).describe(
			'Welcome screen modal regulations'
		),
		whatAreFanTokensLink: z
			.string()
			.default('https://app.socios.com/support#:~:text=What%20are%20Fan%20Tokens%3F')
			.describe('What are fan tokens link'),
		whatIsCHZLink: z
			.string()
			.default('https://socios.zendesk.com/hc/en-001/articles/360003217678-What-is-CHZ-')
			.describe('What is CHZ link'),
		xLink: z.string().default('https://twitter.com/socios').describe('X link'),
		youtubeLink: z
			.string()
			.default('https://www.youtube.com/channel/UCzglT2jQzKy7z9hNFXN8geg')
			.describe('Youtube link')
	})
	.passthrough()
	.readonly();

export const defaultSchemaValues = SettingsSchema.parse({});

type ConfigSchema = z.infer<typeof SettingsSchema>;

type Literals = keyof {
	[K in keyof ConfigSchema as unknown extends ConfigSchema[K] ? never : K]: ConfigSchema[K];
};

export type SettingsSchemaType = Pick<ConfigSchema, Literals>;
