import { z } from 'zod';

export const LocaleSchema = z.enum([
	'en-GB',
	'es-ES',
	'fr-FR',
	'ar-SA',
	'id-ID',
	'it-IT',
	'pt-BR',
	'pt-PT',
	'pl-PL',
	'de-DE',
	'ru-RU',
	'ko-KR',
	'he',
	'ja-JP',
	'zh-CN',
	'en-US',
	'tr-TR'
]);

export type Locale = z.infer<typeof LocaleSchema>;
