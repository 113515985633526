import { z } from 'zod';

export const PwaForceInstallSchema = z
	.object({
		ios: z.array(z.string()).default([]),
		android: z.array(z.string()).default([])
	})
	.default({});

export type PwaForceInstallType = z.infer<typeof PwaForceInstallSchema>;
