var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2.13.0"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import { initSentryClient } from '@capsicum/common/logger';
import { BrowserProfilingIntegration, browserTracingIntegration } from '@sentry/browser';
import { replayIntegration } from '@sentry/nextjs';

import packageJson from './package.json';

initSentryClient({
	platformType: 'client',
	integrations: [
		browserTracingIntegration(),
		BrowserProfilingIntegration(),
		replayIntegration({
			useCompression: false,
			maskAllText: false,
			maskAllInput: false
		})
	],
	version: packageJson.version
});
