import { z } from 'zod';

export const AppStoreReviewConfigSchema = z
	.object({
		loginsCheckPeriodMinutes: z.number(),
		numberLogins: z.number(),
		loginDaysFormat: z.string(),
		timerValueSec: z.number(),
		showDialogNotOftenThanMins: z.number(),
		userRegisteredNotEarlierThanMonthsAgo: z.number()
	})
	.default({
		loginsCheckPeriodMinutes: 14400,
		numberLogins: 3,
		loginDaysFormat: 'MM/DD/YYYY',
		timerValueSec: 600,
		showDialogNotOftenThanMins: 525600,
		userRegisteredNotEarlierThanMonthsAgo: 1
	});
