// eslint-disable-next-line no-restricted-properties
export const isDev = () => process.env.NODE_ENV === 'development';
// eslint-disable-next-line no-restricted-properties
export const isJest = () => process.env.JEST_WORKER_ID !== undefined;

export const getPlatform = () => (typeof document === 'undefined' ? 'mobile' : 'web');

export const isMobile = () => getPlatform() === 'mobile';

export const isWeb = () => getPlatform() === 'web';

export const isPwaMode = () =>
	typeof window !== 'undefined' &&
	(window.matchMedia('(display-mode: standalone)').matches ||
		('standalone' in window.navigator && window.navigator.standalone) ||
		document.referrer.includes('android-app://'));

export const openCookieConsent = () =>
	typeof window !== 'undefined' && (window as any)?.consentManager?.openConsentManager();
