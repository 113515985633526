import { z } from 'zod';

export const ExchangeSchema = z.object({
	name: z.string(),
	code: z.string(),
	assets: z
		.array(
			z.object({
				shortCode: z.string(),
				ethereum: z.boolean(),
				cc1: z.boolean(),
				cc2: z.boolean()
			})
		)
		.default([])
});

export type ExchangeSchemaType = z.infer<typeof ExchangeSchema>;
