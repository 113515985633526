/* eslint-disable no-restricted-properties */

import * as Sentry from '@sentry/nextjs';

import { isDev } from '../../util/platform';

/**
 * @param {Parameters<typeof import('@sentry/nextjs').init>[0] & { version: string; platformType: string;}} options
 * @returns {void}
 * */
export const initSentry = options => {
	const sentryEnableForced = process.env.NEXT_PUBLIC_SENTRY_FORCE_ENABLE === 'true';
	const { integrations, version, platformType, ...otherOptions } = options || {};

	if (isDev() && !sentryEnableForced) {
		return;
	}

	return Sentry.init({
		debug: isDev(),
		dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
		environment: isDev() ? 'dev' : process.env.NEXT_PUBLIC_ENV,
		release: version,
		attachStackTrace: true,
		initialScope: {
			tags: {
				version: version,
				platform: platformType
			}
		},
		integrations: integrations ?? [],
		...otherOptions
	});
};
