/* eslint-disable @typescript-eslint/no-explicit-any */
import { z } from 'zod';

export default class ZodBuilderUtils {
	static fromJsonStringSchema<Schema extends z.ZodType<any, any>>(schema: Schema, defaultValue?: z.infer<Schema>) {
		return z
			.custom<string>(
				data => {
					if (typeof data !== 'string') return false;

					try {
						JSON.parse(data);
					} catch (error) {
						return false;
					}

					return true;
				},
				`Invalid JSON Value for schema: ${JSON.stringify(schema)}`
			)
			.default(JSON.stringify(defaultValue ?? null))
			.transform(content => JSON.parse(content))
			.pipe(schema);
	}
}
